import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  Text,
  LinkButton,
  useBreakpoint,
  AsyncButton,
  useModalContext,
  Button,
} from '@sketch/components'
import { SKETCH_WEBSITE } from '@sketch/env-config'
import { isMac, isTouchDevice, useDetectTouchSwipe } from '@sketch/utils'
import { useSendDownloadSketchEmailMutation } from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import {
  routes,
  useAnalytics,
  LaunchSketchAlert,
  IndexLayoutExtraProps,
  IndexLayoutContent,
  IndexLayoutHeaderLink,
} from '@sketch/modules-common'

import MacApp from '@sketch/icons/mac-app-logo-128.png'
import MacApp2x from '@sketch/icons/mac-app-logo-128@2x.png'
import MacApp4x from '@sketch/icons/mac-app-logo-128@4x.png'

import WebApp from '@sketch/icons/web-app-logo-128.png'
import WebApp2x from '@sketch/icons/web-app-logo-128@2x.png'
import WebApp4x from '@sketch/icons/web-app-logo-128@4x.png'

import WebBackground from '@sketch/icons/web-app-background.svg'
import MacBackground from '@sketch/icons/mac-app-background.svg'

import {
  ItemAnchor,
  ItemBackground,
  ItemDescription,
  ItemIllustration,
  ItemTitle,
  ItemWrapper,
  Section,
  SectionDescription,
  SectionTitle,
  TitleWrapper,
  SegmentControl,
  ItemBackgroundImage,
  StyledLink,
} from './ChooseYourPathView.styles'

type Segment = 'Explore' | 'Design'
const SEGMENTS: Segment[] = ['Explore', 'Design']
const MAX_BACKGROUND_OPACITY = 0.15

const ILLUSTRATION_SOURCE_BY_NAME = {
  'mac-app': {
    alt: 'Mac App illustration',
    src: MacApp,
    srcSet: `${MacApp2x} 2x, ${MacApp4x} 3x`,
    backgroundImage: MacBackground,
  },
  'web-app': {
    alt: 'Web App illustration',
    src: WebApp,
    srcSet: `${WebApp2x} 2x, ${WebApp4x} 3x`,
    backgroundImage: WebBackground,
  },
}

interface ItemProps {
  title: string
  description: string
  action: React.ReactNode
  icon: keyof typeof ILLUSTRATION_SOURCE_BY_NAME
}

const Item = (props: ItemProps) => {
  const { title, description, action, icon } = props
  const { backgroundImage, ...illustrationProps } =
    ILLUSTRATION_SOURCE_BY_NAME[icon]

  const [mouseX, setMouseX] = useState(0)
  useEffect(() => {
    if (isTouchDevice()) {
      return
    }

    const handleMouseMove = (event: globalThis.MouseEvent) => {
      setMouseX(event.clientX)
    }

    document.addEventListener('mousemove', handleMouseMove)
    return () => document.removeEventListener('mousemove', handleMouseMove)
  }, [])

  const wrapperRef = useRef<HTMLDivElement>(null)
  const opacity = useMemo(() => {
    const wrapperBounds = wrapperRef.current?.getClientRects()[0]

    const wrapperLeft = wrapperBounds?.left || 0
    const wrapperWidth = wrapperBounds?.width || 1
    const halfWrapperWidth = wrapperWidth / 2

    const mouseXPositionRelatedToItemCenter =
      Math.abs(mouseX - wrapperLeft - halfWrapperWidth) / halfWrapperWidth

    const opacity =
      MAX_BACKGROUND_OPACITY * (1 - mouseXPositionRelatedToItemCenter)

    return Math.max(0, Math.min(opacity, 1))
  }, [mouseX])

  return (
    <ItemWrapper ref={wrapperRef}>
      <ItemBackground style={{ opacity: opacity }}>
        <ItemBackgroundImage
          style={{
            /* "as any" was used because react doesn't seem to accept a string here ¯\_(ツ)_/¯ */
            backgroundImage: `url(${backgroundImage})` as any,
          }}
        />
      </ItemBackground>
      <ItemIllustration {...illustrationProps} />
      <Text textStyle="header.primary.G" as={ItemTitle}>
        {title}
      </Text>
      <Text textStyle="copy.quaternary.standard.E" as={ItemDescription}>
        {description}
      </Text>
      {action}
    </ItemWrapper>
  )
}

interface DesignOnYourMacProps {
  isMacDesktop?: boolean
}

const DesignOnYourMac = ({ isMacDesktop }: DesignOnYourMacProps) => {
  const { showToast } = useToast()
  const { trackEvent } = useAnalytics()

  const [requestEmail] = useSendDownloadSketchEmailMutation({
    onError: 'show-toast',
    onCompleted: () => {
      showToast('Email has been sent')
    },
  })

  const { showModal } = useModalContext()

  const action = isMacDesktop ? (
    <>
      <Button
        variant="primary"
        size="32"
        onClick={() => {
          showModal(LaunchSketchAlert, { action: 'openInSketch', shareId: '' })
          trackEvent('CHOOSE YOUR PATH - Open Mac App button clicked')
        }}
      >
        Open Sketch ↗
      </Button>
      <StyledLink
        isUnderlined
        external
        href={`${SKETCH_WEBSITE}/downloads/mac`}
        target="_blank"
        onClick={() => {
          trackEvent('CHOOSE YOUR PATH - Download the Mac App button clicked')
        }}
      >
        Download Sketch for macOS
      </StyledLink>
    </>
  ) : (
    <>
      <AsyncButton
        variant="secondary"
        size="32"
        onClick={() => {
          trackEvent(
            'CHOOSE YOUR PATH - Send Mac App download email button clicked'
          )

          return requestEmail()
        }}
      >
        Email Me a Download Link
      </AsyncButton>
      <Text textStyle="caption.secondary.B" as={ItemAnchor}>
        Not on your Mac? Grab a download link for later
      </Text>
    </>
  )

  return (
    <Item
      title="Design on Your Mac"
      description="Design, prototype, and illustrate in a truly native macOS app. Work in private — or collaborate in real time."
      icon="mac-app"
      action={action}
    />
  )
}

const ExploreFromYourBrowser = () => {
  const { trackEvent } = useAnalytics()

  return (
    <Item
      title="Explore from Your Browser"
      description="Inspect, organize and discuss designs with your team, or invite stakeholders as Guests — on any device or browser."
      icon="web-app"
      action={
        <LinkButton
          variant="tertiary"
          size="32"
          to={routes.ENTRY.create({})}
          onClick={() => {
            trackEvent('CHOOSE YOUR PATH - Continue to Web button clicked')
          }}
        >
          Continue in the Browser
        </LinkButton>
      }
    />
  )
}

interface ChooseYourPathProps extends IndexLayoutExtraProps {}

const ChooseYourPathView = (props: ChooseYourPathProps) => {
  const { HeaderPortal, useOverrideLayoutProps } = props

  useOverrideLayoutProps({
    maxContainerWidth: '100vw',
    showFooter: true,
  })

  const isMacClient = isMac()
  const isMobile = !useBreakpoint('md')

  const [activeSection, setActiveSection] = useState(SEGMENTS[0])
  const sectionRef = useRef<HTMLElement>(null)

  // Make the section swipeable
  useDetectTouchSwipe(
    sectionRef,
    useCallback(
      movement => {
        if (!isMobile) {
          return
        }

        if (movement === 'left-swipe') {
          setActiveSection(SEGMENTS[0])
        } else {
          setActiveSection(SEGMENTS[1])
        }
      },
      [isMobile]
    )
  )

  let content: React.ReactNode = null
  if (isMobile) {
    content = (
      <>
        <SegmentControl
          segments={SEGMENTS}
          activeSegment={activeSection}
          renderSegmentText={string => string}
          onSegmentClick={segment => {
            setActiveSection(segment)
          }}
        />

        <Section $isMobile ref={sectionRef}>
          {activeSection === 'Design' ? (
            <DesignOnYourMac isMacDesktop={false} />
          ) : (
            <ExploreFromYourBrowser />
          )}
        </Section>
      </>
    )
  } else {
    const sectionItems = [
      <DesignOnYourMac key="mac" isMacDesktop={isMacClient} />,
      <ExploreFromYourBrowser key="web" />,
    ]

    const sectionContent = isMacClient ? sectionItems : sectionItems.reverse()
    content = <Section ref={sectionRef}>{sectionContent}</Section>
  }

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <HeaderPortal>
        <IndexLayoutHeaderLink headerLink="choose-your-path" />
      </HeaderPortal>
      <TitleWrapper>
        <Text textStyle="header.primary.I" as={SectionTitle}>
          Get the Best of Both Worlds
        </Text>
        <Text textStyle="copy.quaternary.standard.E" as={SectionDescription}>
          Create beautiful designs with the award-winning macOS app and share
          them from your browser, on any platform.
        </Text>
      </TitleWrapper>
      {content}
    </IndexLayoutContent>
  )
}

export default React.memo(ChooseYourPathView)
