import { DataProxy } from 'apollo-cache'

import {
  ProjectFragment,
  ProjectFragmentDoc,
  CollectionFragment,
  CollectionFragmentDoc,
  ProjectChangedSubscription,
  GetProjectDetailsQuery,
  GetProjectDetailsQueryVariables,
  GetProjectDetailsDocument,
} from '@sketch/gql-types'
import { dataIdFromObject } from '@sketch/graphql-cache'

import { removeFromPaginated } from '@sketch/modules-common'
import { deleteWorkspaceProject, restoreWorkspaceProject } from '..'
import {
  StorageItemTransferLocation,
  updateCacheAfterStorageItemTransferred,
} from '../../../storageItems/operations/useTransferStorageItem'
import ApolloClient from 'apollo-client'
import { ErrorHandler } from '@sketch/tracing'

interface ProjectCacheOperation {
  cache: DataProxy
  identifier: string
}

interface ProjectRestoredOperation extends ProjectCacheOperation {
  workspaceIdentifier: string
  archived: boolean
}

interface ProjectDeletedOperation extends ProjectCacheOperation {
  workspaceIdentifier: string
  projectData: ProjectFragment
}

export const handleProjectDeleted = (params: ProjectDeletedOperation) => {
  const { cache, identifier, workspaceIdentifier } = params

  deleteWorkspaceProject(cache, workspaceIdentifier, identifier)
}

export const handleProjectRestored = (params: ProjectRestoredOperation) => {
  const { cache, identifier, workspaceIdentifier, archived } = params

  restoreWorkspaceProject(cache, workspaceIdentifier, identifier, archived)
}

export const handleProjectDeletedPermanently = (
  params: ProjectCacheOperation
) => {
  const { cache, identifier } = params

  // Remove the project from the trash query
  removeFromPaginated(cache, { __typename: 'Project', identifier }, key =>
    key.includes('workspaceTrash')
  )
}

export const getCachedProject = (params: ProjectCacheOperation) => {
  const { cache, identifier } = params

  try {
    return (
      cache.readFragment<ProjectFragment>({
        fragment: ProjectFragmentDoc,
        fragmentName: 'Project',
        id: dataIdFromObject({
          __typename: 'Project',
          identifier,
        })!,
      }) || undefined
    )
  } catch (e) {
    // This is a preventive way of returning an error to the UI
    // If this returns an error we simulate that the object does not exist
    return undefined
  }
}

export const getCachedCollection = (params: ProjectCacheOperation) => {
  const { cache, identifier } = params

  try {
    return (
      cache.readFragment<CollectionFragment>({
        fragment: CollectionFragmentDoc,
        fragmentName: 'Collection',
        id: dataIdFromObject({
          __typename: 'Collection',
          identifier,
        })!,
      }) || undefined
    )
  } catch (e) {
    // This is a preventive way of returning an error to the UI
    // If this returns an error we simulate that the object does not exist
    return undefined
  }
}

export const queryProjectDetails = async (
  client: ApolloClient<object>,
  identifier: string
) => {
  try {
    return client.query<
      GetProjectDetailsQuery,
      GetProjectDetailsQueryVariables
    >({
      query: GetProjectDetailsDocument,
      variables: { projectIdentifier: identifier },
      fetchPolicy: 'network-only',
    })
  } catch (e) {
    return null
  }
}

export const handleProjectTransferred = async (
  client: ApolloClient<object>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  data: Extract<
    ProjectChangedSubscription['projectChanged'],
    { __typename: 'ProjectTransferredEvent' }
  >
) => {
  // Query project details, this serves us two purposes:
  // 1. Update the cache values with the new project details
  // 2. Get the project object that we can use later in this function
  const projectQuery = await queryProjectDetails(
    client,
    data.project.identifier
  )
  const project = projectQuery?.data.project
  if (!project) {
    // There were issues fetching the project details
    // Abort updating the cache
    ErrorHandler.shouldNeverHappen(
      'Project details not found after project transfer'
    )
    return
  }

  const location: StorageItemTransferLocation = {
    source: {
      workspaceId: data.oldWorkspaceIdentifier!,
      projectId: data.oldParentProjectIdentifier || undefined,
    },
    destination: {
      workspaceId: project.workspace.identifier,
      projectId: project.parentProjectIdentifier || undefined,
    },
  }

  updateCacheAfterStorageItemTransferred({
    cache: client,
    item: project,
    location,
  })

  return { item: project, location }
}
