import { routes } from '@sketch/modules-common'

import {
  GetProjectsQuery,
  ProjectInSidebarAndHeaderFragment,
} from '@sketch/gql-types'
import { ProjectLink } from '../types'

type ProjectsById = {
  [key: string]: ProjectLink
}

export function normalizeQueryData(data: GetProjectsQuery | undefined) {
  if (!data) return {}

  const projects = data.workspace.projects
  const draftsProject = data.workspace.draftsProject

  return {
    projects,
    draftsProject,
    allProjects: [...draftsProject.entries, ...projects.entries],
  }
}

// Takes the projects data and normalizes it to a structure that can be used by
// different components, returning a list of pinned projects, root projects and
// an object of projects by id
export function parseProjects(
  entries: ProjectInSidebarAndHeaderFragment[],
  workspaceId: string
) {
  // Create needed structure from project data, and fill the links
  const projectsLink: ProjectLink[] = entries.map(project => ({
    project,
    nestedProjects: [],
    link: routes.WORKSPACE_PROJECT.create({
      projectId: project.identifier,
      workspaceId,
    }),
  }))

  // Create a plain projects list by id, it's easier to manipulate
  const projectsById = projectsLink.reduce(
    (acc: { [key: string]: ProjectLink }, projectLink) => {
      acc[projectLink.project.identifier] = projectLink
      return acc
    },
    {}
  )

  // Fill the nested projects
  projectsLink.forEach(projectLink => {
    const parentId = projectLink.project.parentProjectIdentifier

    if (parentId && projectsById[parentId]) {
      projectsById[parentId].nestedProjects.push(projectLink)
    }
  })

  const pinnedProjects = projectsLink.filter(
    ({ project }) => project.pinnedByCurrentUserAt
  )

  const rootProjects = projectsLink.filter(
    project => !project.project.parentProjectIdentifier
  )

  return { pinnedProjects, rootProjects, projectsById } as const
}

// My drafts project is a bit special, because it should be accessed via
// `.../drafts` url, and not with the project identifier like any other
// project, that's why we need to tweak these drafts lists
export function parseDrafts(
  workspaceId: string,
  drafts: ProjectLink[],
  byId: ProjectsById
): [ProjectLink[], ProjectsById] {
  const myDrafts = drafts[0]

  if (!myDrafts) {
    return [[], {}]
  }

  const myDraftsId = myDrafts.project.identifier

  const rootDraftProjectsSorted: ProjectLink[] = [
    {
      ...myDrafts,
      link: routes.WORKSPACE_DRAFTS.create({ workspaceId }),
    },
  ]
  const draftProjectsById: ProjectsById = {
    ...byId,
    [myDraftsId]: {
      ...byId[myDraftsId],
      link: routes.WORKSPACE_DRAFTS.create({ workspaceId }),
    },
  }

  return [rootDraftProjectsSorted, draftProjectsById]
}
