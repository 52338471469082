import React, { useCallback, useEffect, useRef } from 'react'
import Helmet from 'react-helmet'
import { useHistory } from 'react-router'
import { History, LocationDescriptor } from 'history'
import ApolloClient from 'apollo-client'

import { useApolloClient } from '@apollo/react-hooks'
import * as Sentry from '@sentry/browser'
import * as Yup from 'yup'

import {
  IndexLayoutExtraProps,
  IndexLayoutContent,
  IndexLayoutHeaderLink,
  routes,
  useMarketingCookies,
  getAllAuthorizations,
  useQueryParams,
  removeAuthorizationByAuthToken,
} from '@sketch/modules-common'

import { DocumentHead, LoadingPlaceholder } from '@sketch/components'
import { authorizationKeys, localStorageKeys } from '@sketch/constants'

import { useThemeContext } from '@sketch/global-styles'
import { removeItem, useEventDispatch } from '@sketch/utils'
import { useRevokeOAuthMutation } from '@sketch/gql-types'
import { ErrorHandler } from '@sketch/tracing'

import { initializeLocalApolloState } from '../../../graphql-cache/src'

import { Wrapper } from './SignOut.styles'

// This array of strings should be a list of auth tokens
type SessionsToRemove = 'all' | 'none' | string[]
type SessionsToRevoke = 'all' | 'none'

type Require<T, K extends keyof T> = T & { [P in K]-?: T[P] }

export interface SignOutRouteParameters {
  revokeSessions?: SessionsToRevoke
  reason?: string
  removeDataFromSessions?: SessionsToRemove
  redirectBackAfterLoginAgain?: boolean
  redirectLocation?: LocationDescriptor
  calledLocation?: LocationDescriptor
}

interface SignOutParameters {
  client: ApolloClient<any>
  history: History
  parameters: Require<
    SignOutRouteParameters,
    | 'reason'
    | 'redirectBackAfterLoginAgain'
    | 'removeDataFromSessions'
    | 'revokeSessions'
  >
}

declare module '@sketch/utils' {
  export interface EventsMap {
    signOut: {}
  }
}

const parametersSchema = Yup.object({
  reason: Yup.string().default('Uncategorized reason'),
  redirectBackAfterLoginAgain: Yup.boolean().default(false),
  removeDataFromSessions: Yup.mixed<SessionsToRemove>()
    .oneOf(['all', 'none', Yup.array(Yup.string()) as any])
    .default('all'),
  revokeSessions: Yup.mixed<SessionsToRevoke>()
    .oneOf(['all', 'none'])
    .default('all'),
  redirectLocation: Yup.mixed<LocationDescriptor>().oneOf([
    Yup.string(),
    Yup.object() as any,
  ]),
  calledLocation: Yup.mixed<LocationDescriptor>().oneOf([
    Yup.string(),
    Yup.object() as any,
  ]),
})

// Function to be used in cases where a hook cannot be used.
export const signOut = async ({
  client,
  history,
  parameters,
}: SignOutParameters) => {
  const {
    calledLocation,
    reason,
    redirectBackAfterLoginAgain,
    redirectLocation,
    removeDataFromSessions,
  } = parametersSchema.cast(parameters)

  const signInPageLocation: LocationDescriptor = redirectLocation || {
    pathname: routes.SIGN_IN.create({}),
    state:
      redirectBackAfterLoginAgain && calledLocation
        ? { from: calledLocation }
        : {},
  }

  Sentry.captureMessage(`Sign Out: ${reason}`, scope => {
    scope.addBreadcrumb({
      category: 'Authentication',
      message: 'Signing the user out',
      level: 'log',
    })

    return scope
  })

  if (removeDataFromSessions === 'none') {
    return
  }

  const allAuthorizations = getAllAuthorizations()

  /**
   * Since removeDataFromSessions isn't 'all' or 'none', it's a list of auth tokens
   * of the sessions that should have the data removed. As we have more active sessions
   * than we're gonna remove, it means that we need to remove each one manually to keep
   * the other ones.
   */
  if (
    removeDataFromSessions !== 'all' &&
    Array.isArray(removeDataFromSessions) &&
    allAuthorizations.length > removeDataFromSessions.length
  ) {
    // Remove the session(s) from local storage
    removeDataFromSessions.forEach(session => {
      removeAuthorizationByAuthToken(client.cache, session)
    })

    return
  }

  /**
   * In this case, removeDataFromSessions is 'all' or is an array of sessions to
   * be removed but we're gonna remove all of them. In this case, we need to remove
   * all data from local storage and clean Apollo Client cache.
   */

  /**
   * The order we follow when cleaning things up is important.
   * If we clean Apollo's cache before removing all local storage entries,
   * Apollo's cache subscriptions will be notified and, if they access the local
   * storage (e.g. to verify the list of authorizations), they will wrongly see
   * there are still ongoing sessions.
   */
  authorizationKeys.forEach(key => localStorage.removeItem(key))

  /**
   * We are redirecting the user after the reset on the store is done,
   * to allow the new path to have a fresh start.
   */
  client.onResetStore(() => {
    if (typeof signInPageLocation === 'string') {
      history.replace(signInPageLocation)
    } else {
      history.replace(signInPageLocation)
    }

    return Promise.resolve()
  })

  // This makes sure we don't have operations unfinished that might
  // cause error when the user logs out.
  client.stop()

  // Client.resetStore method will allow the cache to be cleared and
  // the connections to any query still mounted to be dropped and reconnected
  // if the reconnects don't occur some hooks like "useUserSignedIn" might not update
  //
  // This method uses internally the same logic as client.clearStore
  // https://github.com/apollographql/apollo-client/blob/v2.6.8/packages/apollo-client/src/ApolloClient.ts#L483-L487
  try {
    await client.resetStore()
  } catch (e) {
    ErrorHandler.shouldNeverHappen(
      'Apollo Store should be reset normally, when the user is signout, no queries should be in flight'
    )
  }

  // We need to reinitialize the base apolloStore again because the state is now blank
  initializeLocalApolloState(client.cache)

  if (!redirectBackAfterLoginAgain) {
    removeItem(localStorageKeys.lastWorkspaceIdKey)
  }
}

const useSignOutClean = () => {
  const client = useApolloClient()

  const { resetToMatchSystem } = useThemeContext()
  const { removeCookies } = useMarketingCookies()
  const dispatchSignOutEvent = useEventDispatch('signOut')

  const [revokeOAuth] = useRevokeOAuthMutation({
    onError: 'do-nothing',
  })

  return useCallback(
    async (history: History, parameters: SignOutRouteParameters) => {
      const safeguardParameters = parametersSchema.cast(parameters)

      const { revokeSessions, removeDataFromSessions } = safeguardParameters
      const allAuthorizations = getAllAuthorizations()

      // Revoking sessions
      if (revokeSessions !== 'none') {
        allAuthorizations.forEach(authorization => {
          revokeOAuth({
            variables: {
              token: authorization.fragment.authToken,
            },
          })
        })
      }

      // Removing local variables
      await signOut({
        client,
        history,
        parameters: safeguardParameters,
      })

      // Clear Dark Mode settings from local storage
      resetToMatchSystem()

      if (removeDataFromSessions !== 'none') {
        /**
         * Since we want to remove all sessions, or we're going to have no left session
         * after removing all, we want to remove cookies and dispatch sign out event.
         */
        // Remove marketing cookies
        removeCookies()
        dispatchSignOutEvent({})
      }
    },
    [
      client,
      dispatchSignOutEvent,
      removeCookies,
      resetToMatchSystem,
      revokeOAuth,
    ]
  )
}

export const SignOutView = (props: IndexLayoutExtraProps) => {
  const { HeaderPortal } = props

  const history = useHistory()
  const signOut = useSignOutClean()
  const { parameters } = useQueryParams<'SIGN_OUT'>()

  const signOutFn = async () => {
    const safeguardParameters = () => {
      try {
        return JSON.parse(parameters || '{}')
      } catch {
        return {}
      }
    }

    await signOut(history, safeguardParameters())
  }

  const cachedAsyncSignOut = useRef(signOutFn)
  cachedAsyncSignOut.current = signOutFn

  useEffect(() => {
    cachedAsyncSignOut.current()
  }, [])

  return (
    <IndexLayoutContent center="horizontal" marginTop paddingHorizontal>
      <HeaderPortal>
        <IndexLayoutHeaderLink />
      </HeaderPortal>
      <Helmet>
        <link rel="canonical" href="https://www.sketch.com/signin/" />
        <meta property="og:url" content="https://www.sketch.com/signin/" />
      </Helmet>
      <DocumentHead title="Sign in - It's great to see you again" />
      <Wrapper>
        <LoadingPlaceholder size="64px" />
      </Wrapper>
    </IndexLayoutContent>
  )
}
