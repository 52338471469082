import React, { useCallback, useState } from 'react'

import { ProjectFragment, ShareListItemFragment } from '@sketch/gql-types'

import { Button, Modal, ModalInjectedProps } from '@sketch/components'

import ShareAccessList from '../../../shares/components/ShareAccessList'

import { Bold, CenterIconText, FolderIcon } from './ConfirmMoveModal.styles'

interface ConfirmMoveModalProps extends ModalInjectedProps {
  newProject: ProjectFragment
  share: ShareListItemFragment
  onConfirm: (hideModal: () => void) => Promise<void>
}

export function ConfirmMoveModal(props: ConfirmMoveModalProps) {
  const { hideModal, newProject, share } = props

  const [loading, setLoading] = useState(false)

  // To avoid passing props to the callback, we use a ref to store the current props
  const propsRef = React.useRef(props)
  propsRef.current = props

  const confirm = useCallback(async () => {
    const { hideModal, onConfirm } = propsRef.current
    setLoading(true)
    try {
      await onConfirm(hideModal)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Modal
      title="Move Document?"
      onCancel={hideModal}
      data-testid="confirm-move-modal"
    >
      <Modal.Body>
        <CenterIconText>
          to <FolderIcon />{' '}
          <Bold>
            <span data-testid="project-name">{newProject.name}</span>
          </Bold>
        </CenterIconText>
        <ShareAccessList
          shareIdentifier={share.identifier}
          destination={{ project: newProject }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={hideModal}>Cancel</Button>
        <Button loading={loading} onClick={confirm} variant="primary">
          Move Document
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
