import React, { useEffect, useRef, useState } from 'react'

import {
  Tooltip,
  LoadingPlaceholder,
  SelectDropdownItem,
  SelectDropdownSubMenu,
  useSelectDropdownMenu,
} from '@sketch/components'

import { HeaderItem } from '../MoveShareToProject.styles'
import {
  ErrorState,
  LoadingState,
  WorkspacesItem,
  Chevron,
  WorkspaceLogo,
  AdditionalWrapper,
} from './OtherWorkspacesMenu.styles'

import { ReactComponent as AllDocumentsIcon } from '@sketch/icons/documents-prism-16'
import { ReactComponent as WorkspaceIcon } from '@sketch/icons/workspace-16'
import { ReactComponent as FolderClosed } from '@sketch/icons/folder-closed-16'
import { ReactComponent as RestrictedProject } from '@sketch/icons/lock-16'

// GraphQL
import { ProjectFragment, WorkspaceMinimalFragment } from '@sketch/gql-types'
import { useGetProjectsLazy } from '../../../operations'
import { SignedOutLabel } from '../../../../workspace/containers/WorkspacesDropdown'
import { useSignInToUnauthorizedWorkspace } from '../../../../sso/operations'
import { useUserAuthorizations } from '@sketch/modules-common'

const allDocumentsProject: ProjectFragment = {
  __typename: 'Project',
  name: 'All Documents',
  description: '',
  identifier: '',
  projectType: 'STANDARD',
  deletedAt: null,
  workspaceAccessLevel: 'DEFAULT',
  pinnedByCurrentUserAt: null,
  parentProjectIdentifier: null,
  archivedAt: null,
  topLevelProject: null,
}

const getProjectIcon = (project: ProjectFragment) => {
  const isProjectRestricted = project.workspaceAccessLevel === 'NONE'

  // This equality works because we are appending the "allDocumentsProject" in
  // WorkspaceProjectsList (down below):
  // ---> "items = [allDocumentsProject, ...(projects?.entries || [])]"
  const isAllDocumentsProject = project === allDocumentsProject

  // Lock Icon
  if (isProjectRestricted) {
    return RestrictedProject
  }

  // All Documents Icon
  if (isAllDocumentsProject) {
    return AllDocumentsIcon
  }

  // Regular project, a closed folder icon
  return FolderClosed
}

type AdditionalItems = 'loading' | 'error'

interface WorkspaceProjectsListProps {
  workspace: WorkspaceMinimalFragment
}

const SSOSignOutWorkspace = ({ workspace }: WorkspaceProjectsListProps) => {
  const { signIn } = useSignInToUnauthorizedWorkspace()

  const [hasPopoverOpened, setPopoverOpened] = useState(false)
  const popupRef = useRef<Window | null>(null)
  const { setForceExpanded } = useSelectDropdownMenu()

  useEffect(() => {
    /**
     * Since the popup URL will change between the IdP and
     * the Cloud, we can't relay on window messages nor events
     * because the user might decide to close the popup at any moment
     * even on the Idp url.
     *
     * Therefore we will set a interval to check if the popup is
     * opened and update the UI when he is not.
     */
    if (!hasPopoverOpened) {
      return
    }

    const interval = setInterval(() => {
      if (popupRef.current?.closed) {
        setPopoverOpened(false)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [hasPopoverOpened])

  let additional: React.ReactNode = null
  if (hasPopoverOpened) {
    additional = <LoadingPlaceholder size="16px" />
  } else {
    additional = <SignedOutLabel expired={false} />
  }

  return (
    <Tooltip content="Click here to sign-in" placement="bottom-start">
      <SelectDropdownItem
        additional={<AdditionalWrapper>{additional}</AdditionalWrapper>}
        text={workspace.name}
        forceHover={hasPopoverOpened}
        icon={({ className }) => (
          <WorkspaceLogo
            className={className}
            workspaceName={workspace.name}
            src={workspace.avatar?.large}
            size="24px"
          />
        )}
        onClick={event => {
          event.stopPropagation()

          signIn({
            workspace,
            showPopup: true,
            onPopup: window => {
              if (!window) {
                /**
                 * This would mean that it wasn't possible to
                 * open the popup.
                 */
                return
              }

              popupRef.current = window
              setPopoverOpened(true)
              setForceExpanded(true)
            },
          })
        }}
      />
    </Tooltip>
  )
}

const WorkspaceProjectsList = function (props: WorkspaceProjectsListProps) {
  const { workspace } = props
  const { projects, loading, error, getProjects } = useGetProjectsLazy()
  const { hasAccessToWorkspace } = useUserAuthorizations()

  let items: (ProjectFragment | AdditionalItems)[] = ['loading']

  if (loading) {
    items = ['loading' as const]
  } else if (error) {
    items = ['error' as const]
  } else {
    items = [allDocumentsProject, ...(projects?.entries || [])]
  }

  const workspaceId = workspace.identifier
  const workspaceSignOut = !hasAccessToWorkspace(workspace)
  const workspaceDisabled = workspaceSignOut || workspace.status !== 'ACTIVE'

  const renderWorkspaceLogo = ({ className }: React.ComponentProps<'div'>) => (
    <WorkspaceLogo
      className={className}
      workspaceName={workspace.name}
      src={workspace.avatar?.large}
      size="24px"
    />
  )

  if (workspaceSignOut) {
    return <SSOSignOutWorkspace workspace={workspace} />
  }

  return (
    <SelectDropdownSubMenu
      // usePortal to allow the "Move > Project > Other Workspaces" dropdown
      // to recalculate its opening side if no space is available on the side
      usePortal
      disabled={workspaceDisabled}
      headerItem={<HeaderItem>{workspace.name}</HeaderItem>}
      renderTrigger={props => (
        <WorkspacesItem
          disabled={workspaceDisabled}
          text={workspace.name}
          icon={renderWorkspaceLogo}
          additional={<Chevron />}
          onMouseEnter={() => {
            getProjects({ variables: { workspaceId } })
          }}
          {...props}
        />
      )}
      items={items}
      value={workspace}
      renderItem={(item, props) => {
        const projectItem = item as ProjectFragment
        const icon = getProjectIcon(projectItem)

        if (typeof item === 'object') {
          return (
            <SelectDropdownItem
              active={props.active}
              icon={icon}
              text={item.name}
              onClick={props.onClick}
            />
          )
        } else if (item === 'error') {
          return <ErrorState />
        } else {
          return <LoadingState />
        }
      }}
    />
  )
}

interface OtherWorkspacesMenuProps {
  workspaces: WorkspaceMinimalFragment[]
}

export function OtherWorkspacesMenu(props: OtherWorkspacesMenuProps) {
  const { workspaces } = props

  if (workspaces.length === 0) {
    return null
  }

  return (
    <SelectDropdownSubMenu
      items={workspaces}
      headerItem={<HeaderItem>Other Workspaces</HeaderItem>}
      renderTrigger={({ active, ...props }) => (
        <SelectDropdownItem
          active={active}
          text="Other Workspaces"
          icon={WorkspaceIcon}
          additional={<Chevron />}
          {...props}
        />
      )}
      renderItem={workspace => <WorkspaceProjectsList workspace={workspace} />}
      value="Other Workspaces"
    />
  )
}
