import React, { PropsWithChildren } from 'react'

import {
  TreeItem,
  IconWrapper,
  Chevron,
  ChevronButton,
  ChevronEmpty,
  Pill,
  ActionWrapper,
  ActionButton,
  PlusIcon,
  InlineEditorForm,
  InlineEditorFormContainer,
  GroupContainer,
} from './TreeNode.styles'
import { useTreeNodeState, useTreeState } from './state'
import { ReactComponent as FolderClosedIcon } from '@sketch/icons/folder-closed-16'

import { Skeleton } from '@sketch/components'
import { castError } from '@sketch/utils'
import { useToast } from '@sketch/toasts'

export interface TreeNodeProps extends PropsWithChildren<{}> {
  depth: number
  icon: React.ReactNode | ((isOpen: boolean) => React.ReactNode)
  hasChildren: boolean
  id: string
  label: React.ReactNode
  /**
   * @default false
   */
  canCreateNestedProjects?: boolean
  onCreateProject?: (projectName: string) => Promise<string | undefined>
  dimmed?: boolean
  disabled?: boolean
}

export const TreeNode = (props: TreeNodeProps) => {
  const {
    id,
    children,
    label,
    hasChildren,
    depth,
    icon,
    dimmed,
    onCreateProject,
    disabled,
    canCreateNestedProjects = true,
  }: typeof props = props
  const {
    isOpen,
    isSelected,
    isCurrentDestination,
    creatingState,
    onRefLoaded,
    onFocusedChange,
    onOpenChange,
    onSelect,
    onCreatingProject,
  } = useTreeNodeState(id)

  const iconElement = typeof icon === 'function' ? icon(isOpen) : icon
  const { showToast } = useToast()
  const treeState = useTreeState()

  const shouldBeGrouped = hasChildren || creatingState

  const labelElement = (
    <TreeItem
      aria-disabled={disabled}
      dimmed={shouldBeGrouped ? false : !!dimmed}
      depth={depth}
      tabIndex={0}
      ref={ref => ref && onRefLoaded(ref)}
      role="treeitem"
      aria-current={isCurrentDestination}
      aria-selected={isSelected}
      aria-expanded={isOpen || !!creatingState}
      aria-level={props.depth}
      onFocus={() => !disabled && onFocusedChange(true)}
      onBlur={() => !disabled && onFocusedChange(false)}
      onClick={() => !disabled && onSelect()}
    >
      {shouldBeGrouped ? (
        <ChevronButton
          tabIndex={-1}
          onClick={e => {
            e.stopPropagation()
            onOpenChange(!isOpen)
          }}
        >
          <Chevron />
        </ChevronButton>
      ) : (
        <ChevronEmpty />
      )}
      <IconWrapper>{iconElement}</IconWrapper>
      {label}
      {isCurrentDestination && <Pill variant="primary">Current</Pill>}
      <ActionWrapper>
        {canCreateNestedProjects && (
          <ActionButton
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              treeState.openAllToNode(id)
              onCreatingProject('pending')
            }}
          >
            <PlusIcon />
          </ActionButton>
        )}
      </ActionWrapper>
    </TreeItem>
  )

  if (shouldBeGrouped)
    return (
      <GroupContainer dimmed={!!dimmed}>
        {labelElement}
        <div
          style={isOpen || creatingState ? {} : { display: 'none' }}
          role="group"
        >
          {creatingState && (
            <InlineEditorFormContainer>
              <InlineEditorForm
                depth={depth + 1}
                toggleEditor={() => onCreatingProject(null)}
                icon={{ label: 'Project Name', component: FolderClosedIcon }}
                placeholder="Project Name"
                onSubmit={async values => {
                  let newNodeId: string | undefined
                  try {
                    onCreatingProject('loading')
                    newNodeId = await onCreateProject?.(values.projectName)
                  } catch (e) {
                    const error = castError(e)
                    showToast(error.message, 'negative')
                  } finally {
                    // We are calling `treeState.openAllToNode` twice because we want to handle two scenarios:
                    //  - before creating, in case the parent (current) project had children, we want to
                    //    open all nodes to it and keep it open (even if the user canceled the creation)
                    //  - after creating, we want to open all nodes to the newly created project in case
                    //    parent (current) project didn't have children and this is the first child.
                    treeState.openAllToNode(newNodeId || id)
                    treeState.onSelect(newNodeId || id)
                    treeState.focusNode(newNodeId || id)
                    onCreatingProject(null)
                  }
                }}
              />
            </InlineEditorFormContainer>
          )}
          {children}
        </div>
      </GroupContainer>
    )

  return labelElement
}

export interface TreeNodeSkeletonProps {
  depth: number
}
export const TreeNodeSkeleton = (props: TreeNodeSkeletonProps) => {
  return (
    <TreeItem
      tabIndex={-1}
      depth={props.depth}
      style={{ pointerEvents: 'none' }}
    >
      <ChevronButton tabIndex={-1} style={{ opacity: 0.2 }}>
        <Chevron />
      </ChevronButton>
      <Skeleton width="16px" style={{ marginRight: '8px' }} height="100%" />
      <Skeleton width="50%" height="100%" />
    </TreeItem>
  )
}
