import React, { useRef } from 'react'
import {
  useGetWorkspaces,
  useTreeState,
  useIsCreatingProject,
  UnableToTransferReason,
  UseGetWorkspacesResult,
} from './state'
import { useTreeKeyboardNavigation } from './state.base'
import { ProjectTreeNodes } from './TreeNode.projects'
import {
  GenericError,
  Separator,
  Tooltip,
  WorkspaceLogo,
} from '@sketch/components'
import { TreeNode, TreeNodeSkeleton } from './TreeNode'
import { CollapsibleTree } from './state.base/CollapsibleTree'
import { useOnListChange } from './state/useOnListChange'
import { TreeContainer, TreeOverlay } from './TreeNode.styles'
import { useCreateSimpleProject } from '../../hooks/useCreateProject'
import { WorkspaceMinimalFragment } from '@sketch/gql-types'

interface UnavailableWorkspacesTreeProps {
  workspaces: UseGetWorkspacesResult
}

interface UnavailableReasonToMessageProps {
  reason: UnableToTransferReason
  workspace: WorkspaceMinimalFragment
  currentWorkspace: WorkspaceMinimalFragment
}

const unavailableReasonToMessage = (
  props: UnavailableReasonToMessageProps
): string => {
  const { reason, workspace } = props

  switch (reason) {
    case 'TO_PERSONAL':
      return 'You cannot move documents into a legacy Personal Workspace.'
    case 'TO_MISSING_BILLING':
      return `Please check the billing details for "${workspace.name}" or contact customer support.`
    case 'TO_NOT_EDITABLE':
      return `You need to be an Editor or Admin in this Workspace to move documents into it.`
    case 'OUT_PERSONAL':
      return 'You cannot move documents from a legacy Personal Workspace.'
    case 'OUT_TRIALING':
      return 'You cannot move items from a Workspace during your trial.'
    case 'OUT_NOT_ADMIN':
      return `You need to be an administrator of this workspace to move document from it.`
  }
}

const UnavailableWorkspacesTree = (props: UnavailableWorkspacesTreeProps) => {
  const { workspaces } = props
  if (workspaces.unavailable.length === 0) return null

  return (
    <>
      <Separator marginX={4} marginY={2} />

      {workspaces.unavailable.map(({ workspace, reason }) => (
        <Tooltip
          key={workspace.identifier}
          placement="top-start"
          content={unavailableReasonToMessage({
            reason,
            workspace,
            currentWorkspace: workspaces.currentWorkspace.data,
          })}
        >
          <TreeNode
            id={workspace.identifier}
            depth={0}
            disabled
            dimmed
            canCreateNestedProjects={false}
            icon={
              <WorkspaceLogo
                size="16px"
                workspaceName={workspace.name}
                src={workspace.avatar?.small || workspace.avatar?.large}
              />
            }
            hasChildren={false}
            label={<div>{workspace.name}</div>}
          ></TreeNode>
        </Tooltip>
      ))}
    </>
  )
}

export const WorkspaceTreeNodes = () => {
  const treeState = useTreeState()
  const { isCreatingProject } = useIsCreatingProject()

  const currentWorkspaceId = treeState.currentIds.workspaceId

  const { error, loading, workspaces } = useGetWorkspaces(currentWorkspaceId)

  const { createProject } = useCreateSimpleProject()

  useOnListChange(workspaces?.available || [], (items, isFirstChange) => {
    treeState.setChildren(
      CollapsibleTree.ROOT_ID,
      items.map(x => ({ id: x.identifier, payload: x, hasChildren: true }))
    )

    if (isFirstChange) treeState.setOpenToOnce(currentWorkspaceId)
  })

  const containerRef = useRef<HTMLDivElement>(null)
  useTreeKeyboardNavigation(containerRef, treeState)

  if (loading) {
    return (
      <div>
        <TreeNodeSkeleton depth={0} />
        <TreeNodeSkeleton depth={1} />
        <TreeNodeSkeleton depth={1} />
        <TreeNodeSkeleton depth={0} />
        <TreeNodeSkeleton depth={0} />
      </div>
    )
  }

  if (error || !workspaces) {
    return <GenericError />
  }

  return (
    <TreeContainer
      ref={containerRef}
      role="tree"
      creatingProject={isCreatingProject}
    >
      {isCreatingProject && <TreeOverlay />}
      {workspaces.available.map(x => (
        <TreeNode
          key={x.identifier}
          id={x.identifier}
          depth={0}
          onCreateProject={async name => {
            const result = await createProject({
              workspaceId: x.identifier,
              name,
            })
            return result?.identifier
          }}
          icon={
            <WorkspaceLogo
              size="16px"
              workspaceName={x.name}
              src={x.avatar?.small || x.avatar?.large}
            />
          }
          hasChildren={true}
          label={<div>{x.name}</div>}
        >
          <ProjectTreeNodes key={x.identifier} workspaceId={x.identifier} />
        </TreeNode>
      ))}

      <UnavailableWorkspacesTree workspaces={workspaces} />
    </TreeContainer>
  )
}
