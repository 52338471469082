import React, { useCallback } from 'react'

import { useShareDrop, ShareDropPayload } from '@sketch/modules-common'

import { useToast } from '@sketch/toasts'

import { useTransferStorageItem } from '../../storageItems'
import { ErrorHandler } from '@sketch/tracing'
import { MovedToProjectToast } from '../../storageItems/components'

interface UseNestedProjectDropSharesProps {
  projectIdentifier: string
  workspaceId: string
}

export const useNestedProjectDropShares = ({
  projectIdentifier,
  workspaceId,
}: UseNestedProjectDropSharesProps) => {
  const { showToast } = useToast()

  const [transferItemRaw] = useTransferStorageItem({
    onError: errorMessage => {
      showToast(errorMessage, 'negative')
    },
  })

  const transferItem = useCallback(
    ({
      projectId,
      shareId,
      oldProjectId,
    }: {
      projectId: string
      shareId: string
      oldProjectId: string | undefined
    }) => {
      transferItemRaw({
        onCompleted: data => {
          const storageItem = data.transferStorageItem.storageItem
          const newProject =
            storageItem.__typename === 'Share'
              ? storageItem.project
              : storageItem.parentProjects.find(
                  x => x.identifier === storageItem.parentProjectIdentifier
                )

          if (!newProject) {
            ErrorHandler.shouldNeverHappen('newProject is not defined')
            return
          }

          showToast(
            <MovedToProjectToast
              project={newProject}
              type={storageItem.__typename === 'Share' ? 'Document' : 'Folder'}
              workspaceId={workspaceId}
            />
          )
        },
        item: {
          // TODO: Support drag&dropping projects and documents
          // see: https://linear.app/sketch/issue/SWEB-337/support-draganddroping-projects-and-documents
          __typename: 'Share',
          identifier: shareId,
        },
        location: {
          source: { workspaceId, projectId: oldProjectId },
          destination: { workspaceId, projectId },
        },
      })
    },
    [showToast, transferItemRaw, workspaceId]
  )

  return useShareDrop({
    onDropped: ({ shareId, share }: ShareDropPayload) => {
      if (!share) {
        ErrorHandler.shouldNeverHappen('share is not defined')
      }
      transferItem({
        projectId: projectIdentifier,
        shareId,
        oldProjectId: share?.project?.identifier,
      })
    },
  })
}
