import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { History, Location } from 'history'

import {
  useUserSignedIn,
  EmptyHeader,
  RouteParams,
  routes,
  useUserProfile,
  useSignOut,
} from '@sketch/modules-common'
import { useToast } from '@sketch/toasts'

import { ReactComponent as LockIcon } from '@sketch/icons/lock-64'

import { Button, PageLayout } from '@sketch/components'

import { useShareRequestAccessMutation } from '@sketch/gql-types'

import {
  ButtonWrapper,
  StyledErrorMessage,
  StyledLinkButton,
} from './RequiresMembershipView.styles'

const NoAccessActions = ({ location }: { location: Location }) => {
  const signOutToSignIn = useSignOut({
    reason: 'Requires membership - SignIn',
  })

  const signOutToSignUp = useSignOut({
    reason: 'Requires membership - Create Account',
    redirectLocation: {
      pathname: routes.SIGN_UP.create({}),
      state: { from: location },
    },
  })

  return (
    <>
      <Button size="32" onClick={signOutToSignUp}>
        Create Account
      </Button>
      <Button size="32" onClick={signOutToSignIn}>
        Sign In
      </Button>
    </>
  )
}

const RequestShareAccessActions = ({
  location,
  history,
  shareIdentifier,
}: {
  location: Location
  history: History
  shareIdentifier: string
}) => {
  const { showToast } = useToast()

  const signOut = useSignOut({
    redirectLocation: {
      pathname: routes.SIGN_IN.create({}),
      state: {
        from: location,
      },
    },
    reason: 'Switching account to access share',
  })

  const [requestShareAccess, { loading }] = useShareRequestAccessMutation({
    redirectErrors: true,
    variables: { shortId: shareIdentifier },
    onError: ({ message }) => {
      showToast(message, 'negative')
      /* Redirect home of the user already requested access before */
      if (message === "You've already requested access") {
        history.push(routes.ENTRY.create({}))
      }
    },
    onCompleted: () => {
      showToast('Request has been sent')
      /* Redirect home instead of showing the ShareAuthorizer NotFound */
      history.push(routes.ENTRY.create({}))
    },
  })

  return (
    <>
      <Button size="32" onClick={() => signOut()}>
        Switch Account
      </Button>
      <Button
        variant="primary"
        size="32"
        onClick={() => requestShareAccess()}
        loading={loading}
      >
        Request Access
      </Button>
    </>
  )
}

const SignUpOrSignInView = ({ location }: { location: Location }) => (
  <>
    <StyledLinkButton
      to={{ pathname: routes.SIGN_IN.create({}), state: { from: location } }}
    >
      Sign In
    </StyledLinkButton>
    <StyledLinkButton
      to={{ pathname: routes.SIGN_UP.create({}), state: { from: location } }}
    >
      Create Account
    </StyledLinkButton>
  </>
)

const ErrorDescription = ({
  isUserSignedIn,
  canRequestAccess,
}: {
  isUserSignedIn: boolean
  canRequestAccess: boolean
}) => {
  if (isUserSignedIn && !canRequestAccess) {
    return (
      <>
        You cannot request access to this document using your Workspace’s SSO
        account. <br />
        To request access, sign in with your email and password — or sign out
        and create an account.
      </>
    )
  } else if (isUserSignedIn) {
    return <>You don’t have permission from the Owner to view this document.</>
  } else {
    return (
      <>You might not have permission from the Owner to view this document.</>
    )
  }
}

interface RequiresMembershipViewProps
  extends RouteComponentProps<RouteParams<'SHARE_VIEW'>> {
  shareIdentifier: string
  canSSOUserRequestAccess?: boolean
}

const RequiresMembershipView = (props: RequiresMembershipViewProps) => {
  const { location, history, shareIdentifier, canSSOUserRequestAccess } = props

  const isUserSignedIn = useUserSignedIn()
  const { data } = useUserProfile()

  const hasPersonalIdentity = data?.me.hasPersonalIdentity || false
  const canRequestAccess =
    hasPersonalIdentity || canSSOUserRequestAccess || false

  let buttons = null

  if (!isUserSignedIn) {
    // Scenario A
    // User doesn't have authorizations, needs to either sign-in or create an account

    buttons = <SignUpOrSignInView location={location} />
  } else if (isUserSignedIn && canRequestAccess) {
    // Scenario B
    // User has authorizations and either has personal identity or
    // the SSO authorization is the matches the SSO workspace

    buttons = (
      <RequestShareAccessActions
        location={location}
        history={history}
        shareIdentifier={shareIdentifier}
      />
    )
  } else {
    // Scenario C
    // User has SSO authorizations but its not the same as the SSO workspace

    buttons = <NoAccessActions location={location} />
  }

  return (
    <PageLayout header={<EmptyHeader />}>
      <StyledErrorMessage
        icon={<LockIcon />}
        iconSize="medium"
        title="The document you’re trying to access is private"
        extra={<ButtonWrapper>{buttons}</ButtonWrapper>}
        description={
          <ErrorDescription
            isUserSignedIn={isUserSignedIn}
            canRequestAccess={canRequestAccess}
          />
        }
      />
    </PageLayout>
  )
}

export default withRouter(RequiresMembershipView)
