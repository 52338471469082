import React from 'react'
import { Redirect } from 'react-router'

import { localStorageKeys } from '@sketch/constants'
import {
  routes,
  DynamicLoadingPage,
  isSsoAuthorization,
  useUserAuthorizations,
  useSignOut,
  mapSsoStartUrl,
} from '@sketch/modules-common'

import { removeItem } from '@sketch/utils'

import WorkspaceAgnosticRedirectRoute from './WorkspaceAgnosticRedirectRoute'

// GQL
import {
  WorkspaceMinimalFragment,
  WorkspaceMinimalWithBillingStatusFragment,
} from '@sketch/gql-types'

interface FallbackWorkspaceRedirectRouteProps {
  workspaces: WorkspaceMinimalWithBillingStatusFragment[]
  personalWorkspace?: WorkspaceMinimalFragment
}

/**
 * This component redirects users when they try to access the default ENTRY
 * page and there is no direct way to know where should they go.
 *
 * We know we can't redirect the user to their last workspace seen, either
 * because they don't have one or because they don't have access to it. Once
 * here, we just try to find a matching workspace the user can see or we just
 * sign them out.
 */
export const FallbackWorkspaceRedirectRoute = ({
  workspaces,
  personalWorkspace,
}: FallbackWorkspaceRedirectRouteProps) => {
  const { authorizations, hasPersonalAuthorization, hasAccessToWorkspace } =
    useUserAuthorizations()

  const signOut = useSignOut({
    redirectLocation: routes.SIGN_UP.create({}),
    reason: 'Workspace Redirect Fallback',
  })

  removeItem(localStorageKeys.lastWorkspaceIdKey)

  if (hasPersonalAuthorization) {
    /**
     * Let's see if the user has a personal workspace where we can send them to.
     */
    if (personalWorkspace) {
      return (
        <Redirect
          to={routes.WORKSPACE_SHARES.create({
            workspaceId: personalWorkspace.identifier,
          })}
        />
      )
    }

    /**
     * User has no personal workspace so let's just look for the first non-SSO
     * workspace and redirect them there.
     */
    const firstNonSsoWorkspace = workspaces.find(
      workspace => !workspace.customer?.ssoEnabled
    )

    if (firstNonSsoWorkspace) {
      if (
        firstNonSsoWorkspace.userRole === 'FINANCE' ||
        firstNonSsoWorkspace.userRole === 'PARTNER'
      ) {
        return (
          <WorkspaceAgnosticRedirectRoute
            workspace={firstNonSsoWorkspace}
            fallbackRoute={routes.WORKSPACE_SETTINGS_PEOPLE.create({
              workspaceId: firstNonSsoWorkspace.identifier,
            })}
          />
        )
      } else {
        return (
          <WorkspaceAgnosticRedirectRoute
            workspace={firstNonSsoWorkspace}
            fallbackRoute={routes.WORKSPACE_SHARES.create({
              workspaceId: firstNonSsoWorkspace.identifier,
            })}
          />
        )
      }
    }
  }

  /**
   * If the user is authenticated but only has SSO sessions OR they only have
   * access to SSO workspaces, then we look for the best matching workspace we
   * can find from their session information.
   */
  const availableWorkspaceIds = authorizations.map(auth =>
    isSsoAuthorization(auth) ? auth.workspaceId : null
  )

  const availableWorkspace = workspaces.find(workspace =>
    availableWorkspaceIds.includes(workspace.identifier)
  )

  /**
   * We found a SSO workspace the user have access to with their SSO
   * credentials.
   */
  if (availableWorkspace) {
    if (
      availableWorkspace.userRole === 'FINANCE' ||
      availableWorkspace.userRole === 'PARTNER'
    ) {
      return (
        <Redirect
          to={routes.WORKSPACE_SETTINGS_PEOPLE.create({
            workspaceId: availableWorkspace.identifier,
          })}
        />
      )
    } else {
      return (
        <Redirect
          to={routes.WORKSPACE_SHARES.create({
            workspaceId: availableWorkspace.identifier,
          })}
        />
      )
    }
  }

  const firstSsoWorkspace = workspaces.find(
    workspace => workspace.customer?.ssoEnabled
  )

  /**
   * At this point, the user only has SSO workspaces.
   *
   * hasAccessToWorkspaces() will check if:
   *     - It's an SSO workspace and the user has an SSO auth for that workspace
   *     - User is guest in an SSO workspace, and it has a personal auth
   *     - User is admin of an SSO workspace, and it has a personal auth
   *     - User is partner of an SSO workspace
   */
  if (firstSsoWorkspace && hasAccessToWorkspace(firstSsoWorkspace)) {
    const isAdmin = firstSsoWorkspace?.userRole === 'ADMIN'

    if (isAdmin) {
      return (
        <WorkspaceAgnosticRedirectRoute
          workspace={firstSsoWorkspace}
          fallbackRoute={routes.WORKSPACE_SETTINGS_PEOPLE.create({
            workspaceId: firstSsoWorkspace.identifier,
          })}
        />
      )
    } else {
      return (
        <WorkspaceAgnosticRedirectRoute
          workspace={firstSsoWorkspace}
          fallbackRoute={routes.WORKSPACE_SHARES.create({
            workspaceId: firstSsoWorkspace.identifier,
          })}
        />
      )
    }
  }

  /**
   * At this point the user has no access to any SSO Workspace (the session
   * information doesn't match any available workspace).
   *
   * We try to make the user sign in to the available SSO Workspace
   */
  if (firstSsoWorkspace?.customer?.ssoStartUrl) {
    window.location.href = mapSsoStartUrl(
      firstSsoWorkspace?.customer.ssoStartUrl
    )

    return <DynamicLoadingPage />
  }

  /**
   * If everything else fails we just sign out the user.
   * This happens ONLY once we made sure there is no workspace we can redirect
   * the user to:
   *   * The user has no last workspace seen.
   *   * The user has no access to their last workspace seen.
   *   * The user has no access to any non-SSO workspace.
   *   * The user does not belong to any SSO workspace.
   */
  signOut()
  return <DynamicLoadingPage />
}
