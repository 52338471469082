import {
  GetWorkspacesQuery,
  useGetWorkspacesQuery,
  WorkspaceMinimalFragment,
  WorkspaceMinimalWithBillingStatusFragment,
} from '@sketch/gql-types'
import { useMemo } from 'react'
import { isMemberWorkspace } from '../../../../workspace/utils'
import { isTruthy } from '@sketch/utils'
import { ErrorHandler } from '@sketch/tracing'

// eslint-disable-next-line no-restricted-imports
import { BillingStatus } from '@sketch/gql-types/expansive'

type WorkspaceWrapped =
  | { type: 'PERSONAL'; data: WorkspaceMinimalFragment }
  | { type: 'REGULAR'; data: WorkspaceMinimalWithBillingStatusFragment }

const isEditableWorkspace = (workspace: WorkspaceMinimalFragment) => {
  return (
    isMemberWorkspace(workspace) &&
    (workspace.userCanEdit || workspace.userRole === 'ADMIN')
  )
}

// TODO: Replace this with a value received from the BE
//  see: https://linear.app/sketch/issue/SWEB-370/replace-local-active-subscription-estimation-with-a-flag-received-from
const allowedToTransferInBillingStatus: BillingStatus[] = [
  'ACTIVE',
  'TRIALING',
  'INCOMPLETE',
  'PAST_DUE',
]
type UnableToTransferToReason =
  | 'TO_PERSONAL'
  | 'TO_MISSING_BILLING'
  | 'TO_NOT_EDITABLE'

const getUnableToTransferToReason = (
  workspace: WorkspaceWrapped
): UnableToTransferToReason | null => {
  if (workspace.type === 'PERSONAL') return 'TO_PERSONAL'
  if (!isEditableWorkspace(workspace.data)) return 'TO_NOT_EDITABLE'

  const currentRegular = workspace.data
  const billingStatus = currentRegular.customer?.billing?.status
  if (!billingStatus) return 'TO_MISSING_BILLING'

  if (allowedToTransferInBillingStatus.includes(billingStatus)) return null
  return 'TO_MISSING_BILLING'
}

const filterDestinationWorkspaces = (otherWorkspaces: WorkspaceWrapped[]) => {
  const available: WorkspaceMinimalFragment[] = []
  const unavailable: {
    workspace: WorkspaceMinimalFragment
    reason: UnableToTransferToReason
  }[] = []

  for (const workspace of otherWorkspaces) {
    const unableToTransferToReason = getUnableToTransferToReason(workspace)
    if (unableToTransferToReason) {
      unavailable.push({
        workspace: workspace.data,
        reason: unableToTransferToReason,
      })
    } else {
      available.push(workspace.data)
    }
  }

  return { available, unavailable }
}

type UnableToTransferOutReason =
  | 'OUT_PERSONAL'
  | 'OUT_TRIALING'
  | 'OUT_NOT_ADMIN'
const getUnableToTransferOutReason = (
  currentWorkspace: WorkspaceWrapped
): UnableToTransferOutReason | null => {
  if (currentWorkspace.type === 'PERSONAL') return 'OUT_PERSONAL'
  const currentRegular = currentWorkspace.data

  if (currentRegular.customer?.billing?.status === 'TRIALING') {
    return 'OUT_TRIALING'
  }

  if (currentRegular.userRole !== 'ADMIN') {
    return 'OUT_NOT_ADMIN'
  }

  return null
}

export type UnableToTransferReason =
  | UnableToTransferOutReason
  | UnableToTransferToReason

export interface WorkspacesTransferAvailability {
  available: WorkspaceMinimalFragment[]
  unavailable: {
    workspace: WorkspaceMinimalFragment
    reason: UnableToTransferReason
  }[]
}

const filterWorkspaces = (
  currentWorkspace: WorkspaceWrapped,
  otherWorkspaces: WorkspaceWrapped[]
): WorkspacesTransferAvailability => {
  const unableToTransferOutReason =
    getUnableToTransferOutReason(currentWorkspace)
  if (unableToTransferOutReason) {
    return {
      available: [currentWorkspace.data],
      unavailable: otherWorkspaces.map(x => ({
        workspace: x.data,
        reason: unableToTransferOutReason,
      })),
    }
  }

  const { available, unavailable } =
    filterDestinationWorkspaces(otherWorkspaces)

  return { available: [currentWorkspace.data, ...available], unavailable }
}

const normalizeWorkspaces = (
  query: GetWorkspacesQuery,
  currentWorkspaceId: string
) => {
  const personalWorkspaceWrapped: WorkspaceWrapped | null = query.me
    .personalWorkspace
    ? {
        type: 'PERSONAL',
        data: query.me.personalWorkspace,
      }
    : null

  const otherWorkspacesWrapped: WorkspaceWrapped[] = query.me.workspaces.map(
    x => ({
      type: 'REGULAR',
      data: x,
    })
  )
  const allWorkspacesWrapped = [
    ...[personalWorkspaceWrapped].filter(isTruthy),
    ...otherWorkspacesWrapped,
  ]

  // push current workspace to the top
  let currentWorkspace: WorkspaceWrapped | null = null
  const otherWorkspaces: WorkspaceWrapped[] = []

  for (const workspace of allWorkspacesWrapped) {
    if (workspace.data.identifier === currentWorkspaceId) {
      currentWorkspace = workspace
    } else {
      otherWorkspaces.push(workspace)
    }
  }

  return {
    currentWorkspace,
    otherWorkspaces,
    allWorkspaces: allWorkspacesWrapped,
  }
}

export interface UseGetWorkspacesResult extends WorkspacesTransferAvailability {
  allWorkspaces: WorkspaceWrapped[]
  currentWorkspace: WorkspaceWrapped
}
export const useGetWorkspaces = (currentWorkspaceId: string) => {
  const { data, loading, error } = useGetWorkspacesQuery({
    onCompleted() {
      // Don't use onCompleted - it is not fired when the query is fetched from the cache
      // see: https://github.com/apollographql/react-apollo/issues/2177
    },
  })

  const workspaces = useMemo<UseGetWorkspacesResult | null>(() => {
    if (!data) return null
    const { currentWorkspace, otherWorkspaces, allWorkspaces } =
      normalizeWorkspaces(data, currentWorkspaceId)

    if (!currentWorkspace) {
      ErrorHandler.shouldNeverHappen(
        'The current workspace is not found, currentWorkspaceId: ' +
          currentWorkspaceId
      )
      return null
    }

    return {
      currentWorkspace,
      allWorkspaces,
      ...filterWorkspaces(currentWorkspace, otherWorkspaces),
    }
  }, [data, currentWorkspaceId])

  return { workspaces, loading, error }
}
