import {
  ProjectFragment,
  StorageItemFragment,
  WorkspaceMinimalFragment,
} from '@sketch/gql-types'
import { removeFromPaginated, removeQuery } from '@sketch/modules-common'
import { DataProxy } from 'apollo-cache'
import { updateProjectListCounts } from '../../../projects/operations'
import type { StorageItemTransferLocation } from './useTransferStorageItem'
import { ErrorHandler } from '@sketch/tracing'
import { castError } from '@sketch/utils'

export interface UpdateStorageItemsCacheArgs {
  cache: DataProxy
  location: StorageItemTransferLocation
  item: Pick<StorageItemFragment, '__typename' | 'identifier'>
}

export const updateCacheAfterStorageItemTransferred = (
  args: UpdateStorageItemsCacheArgs
) => {
  const { cache, location, item } = args

  // Remove storage item from the previous project
  if (location.source.projectId) {
    const prevProjectId = location.source.projectId
    const prevProject: Pick<ProjectFragment, '__typename' | 'identifier'> = {
      __typename: 'Project',
      identifier: prevProjectId,
    }
    removeFromPaginated(cache, item, prevProject)

    // we are using custom counters for nested projects and shares
    // therefore we need to update them manually
    try {
      updateProjectListCounts(cache, prevProjectId, oldProject => {
        if (item.__typename === 'Share') {
          oldProject.sharesCount--
        } else {
          oldProject.nestedProjectsCount--
        }
      })
    } catch (e) {
      ErrorHandler.ignore(castError(e))
    }
  }

  const prevWorkspaceId = location.source.workspaceId
  const targetWorkspaceId = location.destination.workspaceId

  if (prevWorkspaceId !== targetWorkspaceId) {
    const prevWorkspace: Pick<
      WorkspaceMinimalFragment,
      '__typename' | 'identifier'
    > = {
      __typename: 'Workspace',
      identifier: prevWorkspaceId,
    }
    removeFromPaginated(cache, item, prevWorkspace)

    removeQuery(cache, key => {
      return (
        key.startsWith('$') &&
        key.includes(targetWorkspaceId) &&
        key.includes('.projects')
      )
    })
  }

  const destinationProjectId = location.destination.projectId
  if (destinationProjectId) {
    removeQuery(cache, key => {
      return (
        key.startsWith('$') &&
        key.includes(destinationProjectId) &&
        key.includes('.storageItems')
      )
    })
  }

  if (item.__typename === 'Share') {
    const destinationWorkspaceId = location.destination.workspaceId

    removeQuery(cache, key => {
      return (
        key.startsWith('$') &&
        key.includes(destinationWorkspaceId) &&
        key.includes('.shares')
      )
    })
  }
}
