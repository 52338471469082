import { useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { LocationDescriptor } from 'history'

import { routes } from '../../../routes'

// This array of strings should be a list of auth tokens
type SessionsToRemove = 'all' | 'none' | string[]
type SessionsToRevoke = 'all' | 'none'

export interface SignOutParameters {
  revokeSessions?: SessionsToRevoke
  reason?: string
  removeDataFromSessions?: SessionsToRemove
  redirectBackAfterLoginAgain?: boolean
  redirectLocation?: LocationDescriptor
  calledLocation: LocationDescriptor
}

export const createSignOutPath = (parameters?: SignOutParameters) => {
  const {
    reason,
    redirectBackAfterLoginAgain,
    revokeSessions,
    removeDataFromSessions,
    redirectLocation,
    calledLocation,
  } = parameters || {}

  const stringifiedParameters = encodeURIComponent(
    JSON.stringify({
      reason,
      redirectBackAfterLoginAgain,
      revokeSessions,
      removeDataFromSessions,
      redirectLocation,
      calledLocation,
    })
  )

  return routes.SIGN_OUT.create({
    query: { parameters: stringifiedParameters },
  })
}

/**
 * This hook should be used to revoke your sessions that you're actually logged in in the
 * current device and also remove all local variables related to the sessions. To revoke
 * sessions from other devices the user should use the sessions management section in the
 * /settings page.
 * revokeSessions params:
 * - 'all': will revoke all sessions found in the localStorage
 * - 'none': no session will be revoked within the BE
 * removeDataFromSessions params:
 * - 'all': will remove all sessions found in the localStorage
 * - 'none': no session will be removed from the local variables
 * - string[]: we received an array of auth tokens that we're gonna remove from
 *             the local variables
 */
export const useSignOut = (
  parameters?: OmitSafe<SignOutParameters, 'calledLocation'>
) => {
  const history = useHistory()
  const calledLocation = useLocation()

  const cachedSignOut = useRef(() => {
    history.replace(createSignOutPath({ ...parameters, calledLocation }))
  })

  cachedSignOut.current = () => {
    history.replace(createSignOutPath({ ...parameters, calledLocation }))
  }

  return useCallback(() => {
    cachedSignOut.current()
  }, [])
}
