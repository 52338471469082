import React from 'react'

import {
  useGetProjectsQuery,
  useGetProjectsLazyQuery,
  GetProjectsQueryVariables,
} from '@sketch/gql-types'

import { useOnEvent } from '@sketch/utils'

import {
  parseProjects,
  parseDrafts,
  normalizeQueryData,
} from './useGetProjects.utils'

export interface UseProjectsSwitchProps
  extends OmitSafe<GetProjectsQueryVariables, 'workspaceId'> {
  workspaceId: string
}

export const useGetProjects = ({
  workspaceId,
  after,
}: UseProjectsSwitchProps) => {
  const { data, ...query } = useGetProjectsQuery({
    variables: { workspaceId, after },
  })

  const projectsData = data?.workspace.projects
  const draftsData = data?.workspace.draftsProject

  useOnEvent('storageItemTransferred', ({ location }) => {
    const { destination } = location

    // Not in the same workspace, no need to refetch
    if (destination.workspaceId !== workspaceId) {
      return
    }

    query.refetch()
  })

  const {
    pinnedProjects,
    rootProjects: rootStandardProjects,
    projectsById: standardProjectsById,
  } = React.useMemo(
    () => parseProjects(projectsData?.entries || [], workspaceId),
    [projectsData?.entries, workspaceId]
  )

  const {
    rootProjects: rootDraftProjectsRaw,
    projectsById: draftProjectsByIdRaw,
  } = React.useMemo(
    () => parseProjects(draftsData?.entries || [], workspaceId),
    [draftsData?.entries, workspaceId]
  )

  // Special treatment for drafts (see parseDrafts for a detailed explanation)
  const [rootDraftProjects, draftProjectsById] = React.useMemo(
    () => parseDrafts(workspaceId, rootDraftProjectsRaw, draftProjectsByIdRaw),
    [rootDraftProjectsRaw, draftProjectsByIdRaw, workspaceId]
  )

  return {
    ...query,
    ...normalizeQueryData(data),
    projectsById: { ...standardProjectsById, ...draftProjectsById },
    rootStandardProjects,
    pinnedProjects,
    rootDraftProjects,
  }
}

export const useGetProjectsLazy = () => {
  const [getProjects, workspaceResult] = useGetProjectsLazyQuery()
  const { data, ...rest } = workspaceResult

  return { getProjects, ...rest, ...normalizeQueryData(data) }
}

export function useGetRootProject(workspaceId: string, projectId: string) {
  const { projectsById } = useGetProjects({ workspaceId })

  let currentProject = projectsById[projectId]

  while (currentProject?.project?.parentProjectIdentifier) {
    currentProject =
      projectsById[currentProject.project.parentProjectIdentifier]
  }

  return currentProject
}

type ParentProject = {
  identifier: string
  name: string
}

export function useGetParentProjects(
  workspaceId: string,
  projectId?: string
): ParentProject[] {
  const { projectsById } = useGetProjects({ workspaceId })

  if (!projectId) return []

  const parentProjects: ParentProject[] = []
  let currentProject = projectsById[projectId]

  if (currentProject) {
    parentProjects.push({
      identifier: currentProject.project.identifier,
      name: currentProject.project.name,
    })

    while (currentProject?.project?.parentProjectIdentifier) {
      currentProject =
        projectsById[currentProject.project.parentProjectIdentifier]

      if (currentProject) {
        parentProjects.push({
          identifier: currentProject.project.identifier,
          name: currentProject.project.name,
        })
      }
    }
  }

  return parentProjects.reverse()
}
