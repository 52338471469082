import styled from 'styled-components'

import {
  Button,
  Image,
  LinkButton as LinkButtonUnstyled,
  Text,
} from '@sketch/components'
import { Footer } from '@sketch/modules-common'
import { breakpoint } from '@sketch/global-styles'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 448px;
  margin: 84px auto auto;
`

export const TextInbox = styled(Text.Div).attrs({
  textStyle: 'copy.tertiary.standard.D',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 8px;
`
export const ResendButton = styled(Button)`
  margin-bottom: 12px;

  ${breakpoint('base', 'sm')`
    width: 100%;
  `}
`

export const DoThisLaterButton = styled(LinkButtonUnstyled)`
  margin: 44px auto;
`

export const StyledImage = styled(Image)`
  position: relative;
`

export const FooterWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  width: 100vw;
  max-width: 1100px;
  padding: 0 20px;
  overflow: hidden;

  ${breakpoint('base', 'sm')`
    margin: 0 -16px;
  `}

  ::before {
    z-index: 1;
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(
      180deg,
      rgba(253, 253, 253, 0) 0%,
      rgba(250, 250, 250, 0.84) 53%,
      rgba(250, 250, 250, 1) 100%
    );
  }
`

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 40px);
  min-height: 700px;
  margin: 28px 20px 0 20px;
  flex: 1;
  object-fit: cover;
  object-position: left;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.12);

  ${breakpoint('base', 'md')`
      width: calc(100%);
  `}
`

export const StyledFooter = styled(Footer)`
  z-index: 1;
  width: 100%;
`
