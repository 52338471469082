export const defaultFlags = {
  education: false,
  'mandatory-email-verification': false,
  'workspace-members-filter': false,
  'report-subscriptions-to-sentry': false,
  'cwv-custom-grid': false,
  'restricted-uploads': false,
  'design-systems': false,
  'design-systems-editor': false,
  'components-description': false,
  profiles: false,
  'profile-document-detail-view': false,
  'community-canvas': false,
  'publish-community': false,
  'dark-mode-toggle': false,
  'scim-access': false,
  'frontend-consolidate': false,
  'pages-unread-annotations': false,
  sidebar: false,
  'collections-select-all-documents': false,
  // these flags are used when user is not even signed in
  // it is expected that these flags will be changed
  // using DevTools
  'publication-hearts': false,
  'partner-and-resellers-support': false,
  'nested-projects': false,
  'frames-web': false,
} as const
