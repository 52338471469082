import {
  ProjectDetailsFragment,
  ShareInfoFragment,
  StorageItemFragment,
} from '@sketch/gql-types'
import { useToast } from '@sketch/toasts'
import { ErrorHandler } from '@sketch/tracing'
import { useCallback } from 'react'

import {
  StorageItemTransferLocation,
  useTransferStorageItem,
} from '../../operations'
import {
  TreeNodeTypes,
  useTreeState,
} from '../../../projects/modals/ProjectPickerContent'
import { ConfirmDocumentTransferModal } from './ConfirmTransferModal'
import { useConfirmationModal } from './useConfirmationModal'
import { getLocations } from './getLocation'
import { showMovedSuccessToast } from '../../components'

export interface MoveStorageItemProps {
  data: TreeNodeTypes
}

export const useMoveStorageItem = (
  item: Pick<StorageItemFragment, '__typename' | 'identifier' | 'name'> &
    (
      | Pick<ShareInfoFragment, '__typename' | 'memberships'>
      | Pick<ProjectDetailsFragment, '__typename'>
    ),
  hideModal: () => void
) => {
  const [askForConfirmation, ConfirmationModal] = useConfirmationModal()
  const { showToast } = useToast()
  const treeState = useTreeState()

  const [transferItem, { loading }] = useTransferStorageItem({
    onError: message => showToast(message, 'negative'),
  })

  const moveStorageItem = useCallback(
    (target: TreeNodeTypes) => {
      const { targetWorkspace, transferLocation } = getLocations(
        target,
        treeState
      )

      if (!transferLocation || !targetWorkspace) {
        ErrorHandler.shouldNeverHappen('target location is not defined')
        return
      }

      const isWithinTheSameWorkspace =
        transferLocation.source.workspace.identifier ===
        transferLocation.destination.workspace.identifier

      const location: StorageItemTransferLocation = {
        source: {
          workspaceId: transferLocation.source.workspace.identifier,
          projectId: transferLocation.source.project?.identifier,
        },
        destination: {
          workspaceId: transferLocation.destination.workspace.identifier,
          projectId: transferLocation.destination.project?.identifier,
        },
      }

      const transfer = () =>
        transferItem({
          item,
          location,
          onCompleted: () => {
            showMovedSuccessToast({
              item,
              location: transferLocation,
              showToast,
            })
            hideModal()
          },
        })

      if (!isWithinTheSameWorkspace) {
        const targetProject = target.__typename === 'Project' ? target : null

        if (item.__typename === 'Share') {
          askForConfirmation(
            ConfirmDocumentTransferModal,
            {
              targetWorkspace,
              share: item,
              project: targetProject || undefined,
            },
            ({ membershipsOperation }) => {
              // TODO: Pass membershipsOperation once we will support transferring shares using transferStorageItem mutation
              transfer()
            }
          )
          return
        }
      }

      transfer()
    },
    [askForConfirmation, hideModal, item, showToast, transferItem, treeState]
  )

  return [moveStorageItem, { loading, ConfirmationModal }] as const
}
