import React, { FC } from 'react'
import { Redirect, useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'

import { routes } from '@sketch/modules-common'
import {
  WorkspaceMinimalFragment,
  WorkspaceMinimalWithBillingStatusFragment,
} from '@sketch/gql-types'

interface WorkspaceAgnosticRedirectRouteProps {
  workspace:
    | WorkspaceMinimalWithBillingStatusFragment
    | WorkspaceMinimalFragment
  fallbackRoute: string
}

type NonAgnosticRoutes =
  | typeof routes.WORKSPACE_SETTINGS
  | typeof routes.WORKSPACE_SETTINGS_PEOPLE
  | typeof routes.WORKSPACE_SETTINGS_SETTINGS
  | typeof routes.WORKSPACE_SETTINGS_BILLING
  | typeof routes.WORKSPACE_SETTINGS_SSO
  | typeof routes.WORKSPACE_DISCOVER

/**
 * Map workspace agnostic routes to workspace routes
 */
const getWorkspaceRoute = (path: string): NonAgnosticRoutes => {
  const routeMap = {
    [routes.WORKSPACE_AGNOSTIC_SETTINGS.template()]: routes.WORKSPACE_SETTINGS,
    [routes.WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE.template()]:
      routes.WORKSPACE_SETTINGS_PEOPLE,
    [routes.WORKSPACE_AGNOSTIC_SETTINGS_SETTINGS.template()]:
      routes.WORKSPACE_SETTINGS_SETTINGS,
    [routes.WORKSPACE_AGNOSTIC_SETTINGS_BILLING.template()]:
      routes.WORKSPACE_SETTINGS_BILLING,
    [routes.WORKSPACE_AGNOSTIC_SETTINGS_SSO.template()]:
      routes.WORKSPACE_SETTINGS_SSO,
    [routes.WORKSPACE_AGNOSTIC_DISCOVER.template()]: routes.WORKSPACE_DISCOVER,
    [routes.WORKSPACE_AGNOSTIC_SHARES.template()]: routes.WORKSPACE_SHARES,
  }

  return routeMap[path]
}

/**
 * Redirects users trying to access workspace routes without a workspace id.
 */
const WorkspaceAgnosticRedirectRoute: FC<
  WorkspaceAgnosticRedirectRouteProps
> = ({ workspace, fallbackRoute }) => {
  const { path } = useRouteMatch()
  const history = useHistory()

  const workspaceId = workspace.identifier
  const mappedRoute = getWorkspaceRoute(path)

  const redirectTo = mappedRoute
    ? mappedRoute.create({ workspaceId })
    : fallbackRoute

  /**
   * shouldRedirectToThankYouPage
   *
   * This boolean is true, when a admin user of a workspace that has ended trial
   * and doesn't have a subscription set, tries to access it, via an agnostic route. This is a way of converting
   * users to workspace subscriptions
   */
  const shouldRedirectToThankYouPage =
    workspace.userRole === 'ADMIN' &&
    workspace.type !== 'PERSONAL' &&
    workspace.status === 'INACTIVE' &&
    'billing' in workspace.customer! &&
    !(workspace.customer?.billing?.subscriptionSetUp || false)

  if (shouldRedirectToThankYouPage) {
    return (
      <Redirect
        from={history.location.pathname}
        to={{
          pathname: routes.WORKSPACE_TRIAL_THANK_YOU.create({ workspaceId }),
          state: { redirectTo },
        }}
      />
    )
  }

  return <Redirect from={history.location.pathname} to={redirectTo} />
}

export default WorkspaceAgnosticRedirectRoute
