import { route, param, Route } from 'typesafe-react-router'
import { RouteComponentProps } from 'react-router-dom'
import { getKeys } from '@sketch/utils'

// TODO: Move routes to @sketch/modules-common, https://github.com/sketch-hq/Cloud/issues/16596
export const routes = {
  /**
   * Routes order is important for analytics reporting so change them with
   * caution.
   * e.g. imagine if we change the order of these two routes:
   *
   * WORKSPACE_CREATE: route('workspace', 'create'),
   * WORKSPACE: route('workspace', param('workspaceId')),
   *
   * If WORKSPACE goes before WORKSPACE_CREATE, we will track a different page visit when
   * going to `/workspace/create` because the route will match the WORKSPACE route, being
   * `create` the `workspaceId` parameter.
   */

  /**
   * This app doesn't "own" '/' root path. This path is dedicated to Marketing website.
   * However, it still might be useful to use it in `<Switch>...</ Switch>` routes.
   * Use this path with extreme caution.
   * see https://github.com/sketch-hq/Cloud/issues/2957
   */
  ROOT_LEGACY: route(''),
  ENTRY: route('c'),
  SIGN_IN: route('signin').withQueryParams('sketch', 'source'),
  SIGN_UP: route('signup').withQueryParams(
    'sketch',
    'skipWorkspaceCreation',
    'email',
    'utm_campaign',
    'utm_content',
    'utm_medium',
    'utm_source',
    'utm_term',
    'source'
  ),
  SIGN_IN_GET_STARTED: route('signin', 'get-started'),
  SIGN_OUT: route('signout').withQueryParams('parameters'),
  CHOOSE_YOUR_PATH: route('signup', 'choose-your-path'),
  // User stays here until verifies his account
  CHECK_YOUR_INBOX: route('signup', 'check-your-inbox'),
  FORGOT_PASSWORD: route('forgot').withQueryParams('source'),
  RESET_PASSWORD: route('reset'),
  ACCEPT: route('accept').withQueryParams('token'), // Route used on the email verification token
  CONFIRM: route('confirm').withQueryParams('token'), // Route used when changing an existing email
  SSO_SIGN_IN: route('signin', 'sso').withQueryParams(
    'source',
    'team',
    'shortname'
  ),
  SSO_ERROR: route('signin', 'sso', 'error').withQueryParams(
    'teamName',
    'message'
  ),
  SSO_SUCCESSFUL_SIGN_IN: route('signin', 'sso', 'success').withQueryParams(
    'token',
    'source',
    'from',
    's'
  ),
  SSO_LINK_ACCOUNT: route(
    'signin',
    'sso',
    'link',
    param('workspaceId')
  ).withQueryParams('source', 'from'),
  SSO_TOS: route('signin', 'sso', 'tos', param('workspaceId')).withQueryParams(
    'source',
    'from'
  ),
  VERIFICATION_CODE: route('signin/verification-code').withQueryParams(
    'source'
  ),
  RECOVERY_CODE: route('signin/recovery-code').withQueryParams('source'),
  /** View all Artboards of a single page within a document */
  SHARE_VIEW: route('s', param('shareID')),
  SHARE_PAGE_VIEW: route(
    's',
    param('shareID'),
    'p',
    param('pageUUID')
  ).withQueryParams('annotationStatus'),
  SHARE_PAGE_CANVAS_VIEW: route(
    's',
    param('shareID'),
    'p',
    param('pageUUID'),
    'canvas'
  ).withQueryParams(
    'posX',
    'posY',
    'zoom',
    'search',
    'annotation',
    'annotationStatus'
  ),
  SHARE_INVITE: route('s', param('shareID'), 'invite'),
  /** Email entry point to unsubscribe to a share */
  SHARE_UNSUBSCRIBE: route('s', param('shareID'), 'unsubscribe'),
  /** Invitation Routes */
  PROJECT_ACCEPT_INVITE: route(
    'access-request',
    'project-invite',
    'accept'
  ).withQueryParams('token'),
  /** Email entry point to accept invite */
  SHARE_ACCEPT_INVITE_ROOT: route('share-invite'),
  SHARE_ACCEPT_INVITE: route('share-invite', 'accept').withQueryParams('token'),
  SHARE_ACCEPT_INVITE_SIGN_UP: route('share-invite', 'signup'),
  SHARE_ACCEPT_INVITE_SIGN_IN: route('share-invite', 'signin'),
  SHARE_ACCEPT_INVITE_FORGOT_PASSWORD: route('share-invite', 'forgot'),

  /** Access Request Routes */
  /** Email entry point to accept share access request */
  SHARE_ACCEPT_ACCESS_REQUEST: route('share-access-request', 'accept'),
  /** Email entry point to accept project access request */
  PROJECT_ACCEPT_ACCESS_REQUEST: route(
    'access-request',
    'project',
    param('projectMembershipIdentifier'),
    'accept'
  ),

  PERSONAL_SETTINGS: route('settings'),
  /** Email entry point to unsubscribe about comment activity */
  PERSONAL_SETTINGS_UNSUBSCRIBE: route('settings', 'unsubscribe'),
  // Page to manage your personal access tokens for the public API
  PERSONAL_SETTINGS_ACCESS_TOKENS: route('settings', 'access-tokens'),
  // Creating a token requires authenticating. SSO users are redirected
  // to /create after successfully authenticating, which opens the modal to
  // create a token
  PERSONAL_SETTINGS_ACCESS_TOKENS_CREATE: route(
    'settings',
    'access-tokens',
    'create'
  ),

  // Confirmation page to disable MFA (MultiFactor Authentcation A.K.A 2FA)
  PERSONAL_SETTINGS_DISABLE_MFA: route(
    'settings',
    'disableMfa'
  ).withQueryParams('token'),

  APPEARANCE_SETTINGS: route('settings', 'appearance'),

  /* Workspace routes */
  WORKSPACE_CREATE: route('workspace', 'create').withQueryParams(
    'name',
    'upgrade'
  ),
  WORKSPACE_CREATE_DONE: route('workspace', 'create', param('workspaceId')),
  WORKSPACE_INVITE: route('workspace', 'invite').withQueryParams(
    'token',
    'partner'
  ),
  WORKSPACE: route('workspace', param('workspaceId')),
  WORKSPACE_SHARES: route(
    'workspace',
    param('workspaceId'),
    'shares'
  ).withQueryParams('search'),
  WORKSPACE_SHARED_WITH_ME: route(
    'workspace',
    param('workspaceId'),
    'shared-with-me'
  ),
  WORKSPACE_LIBRARIES: route(
    'workspace',
    param('workspaceId'),
    'libraries'
  ).withQueryParams('search'),
  WORKSPACE_TRASH: route(
    'workspace',
    param('workspaceId'),
    'trash'
  ).withQueryParams('search'),
  WORKSPACE_TRASH_PROJECT: route(
    'workspace',
    param('workspaceId'),
    'trash',
    'p',
    param('projectId')
  ).withQueryParams('search'),
  WORKSPACE_TRASH_COLLECTION: route(
    'workspace',
    param('workspaceId'),
    'trash',
    'p',
    param('projectId'),
    'c',
    param('collectionId')
  ).withQueryParams('search'),
  WORKSPACE_DRAFTS: route(
    'workspace',
    param('workspaceId'),
    'drafts'
  ).withQueryParams('search'),
  WORKSPACE_TEMPLATES: route(
    'workspace',
    param('workspaceId'),
    'templates'
  ).withQueryParams('search'),
  WORKSPACE_PROJECT: route(
    'workspace',
    param('workspaceId'),
    'p',
    param('projectId')
  ).withQueryParams('search'),
  WORKSPACE_COLLECTION: route(
    'workspace',
    param('workspaceId'),
    'p',
    param('projectId'),
    'c',
    param('collectionId')
  ).withQueryParams('search', 'filters'),
  WORKSPACE_ARCHIVE: route(
    'workspace',
    param('workspaceId'),
    'archive'
  ).withQueryParams('search', 'type'),
  // Workspace agnostic routes
  WORKSPACE_AGNOSTIC_SHARES: route('workspace', 'shares'),
  WORKSPACE_AGNOSTIC_SETTINGS: route('workspace', 'settings'),
  WORKSPACE_AGNOSTIC_SETTINGS_PEOPLE: route('workspace', 'settings', 'people'),
  WORKSPACE_AGNOSTIC_SETTINGS_BILLING: route(
    'workspace',
    'settings',
    'billing'
  ),
  WORKSPACE_AGNOSTIC_SETTINGS_SETTINGS: route(
    'workspace',
    'settings',
    'settings'
  ),
  WORKSPACE_AGNOSTIC_SETTINGS_SSO: route('workspace', 'settings', 'sso'),
  WORKSPACE_AGNOSTIC_DISCOVER: route('workspace', 'discover'),

  WORKSPACE_SETTINGS: route('workspace', param('workspaceId'), 'settings'),
  WORKSPACE_SETTINGS_PEOPLE: route(
    'workspace',
    param('workspaceId'),
    'settings',
    'people'
  ),
  WORKSPACE_SETTINGS_BILLING: route(
    'workspace',
    param('workspaceId'),
    'settings',
    'billing'
  ),
  WORKSPACE_SETTINGS_SETTINGS: route(
    'workspace',
    param('workspaceId'),
    'settings',
    'settings'
  ),
  WORKSPACE_SETTINGS_SSO: route(
    'workspace',
    param('workspaceId'),
    'settings',
    'sso'
  ),
  WORKSPACE_SETTINGS_PROFILE: route(
    'workspace',
    param('workspaceId'),
    'settings',
    'profile'
  ),
  WORKSPACE_SETTINGS_PERMISSION_GROUPS: route(
    'workspace',
    param('workspaceId'),
    'settings',
    'groups'
  ),
  WORKSPACE_DISCOVER: route('workspace', param('workspaceId'), 'discover'),
  WORKSPACE_SUBSCRIBE: route('workspace', param('workspaceId'), 'subscribe'),
  WORKSPACE_SUBSCRIBE_PARTNER: route(
    'workspace',
    'subscribe',
    'partner'
  ).withQueryParams('token'),
  WORKSPACE_PROFILE: route('profile', param('shortUrlName')),
  WORKSPACE_PROFILE_DOCUMENT: route(
    'profile',
    param('shortUrlName'),
    param('publicationId')
  ),
  WORKSPACE_PROFILE_EDIT: route(
    'workspace',
    param('workspaceId'),
    'profile',
    'edit'
  ),
  WORKSPACE_TRIAL_THANK_YOU: route(
    'workspace',
    param('workspaceId'),
    'thank-you'
  ),
  COMMUNITY_CANVAS: route('community').withQueryParams(
    'search',
    'sort',
    'period',
    'document-type'
  ),
  COMMUNITY_CANVAS_LISTING: route(
    'community',
    'listing',
    param('listIdentifier')
  ).withQueryParams('search', 'sort', 'period', 'document-type'),

  // All user notifications will now be show on this route
  UPDATES: route('inbox'),
  UPDATES_READ: route('inbox', 'read'),

  ARTBOARD_DETAIL: route(
    's',
    param('shareID'),
    'a',
    param('permanentArtboardShortId')
  ).withQueryParams('annotation', 'annotationStatus'),

  // Access an artboard using its uuid and redirect to the equivalent ARTBOARD_DETAIL URL.
  ARTBOARD_DETAIL_UUID: route(
    's',
    param('shareID'),
    'a',
    'uuid',
    param('artboardUUID')
  ).withQueryParams('annotation', 'annotationStatus'),

  FRAME: route('s', param('shareID'), 'f', param('frameUUID')).withQueryParams(
    'annotation',
    'annotationStatus'
  ),

  /**
   * Access an annotation using its identifier and redirect to the approprate
   * ARTBOARD_DETAIL or SHARE_PAGE_CANVAS_VIEW URL
   */
  ANNOTATION_REDIRECT: route(
    's',
    param('shareId'),
    'annotation',
    param('annotationId')
  ).withQueryParams('subjectType', 'subjectId'),
  /**
   * Legacy prototype route for the v1 original player. Retained for redirect
   * purposes.
   */
  PROTOTYPE_LEGACY_ROUTE_1: route(
    's',
    param('shareID'),
    'a',
    param('permanentArtboardShortId'),
    'play'
  ),
  /**
   * Legacy prototype route for the interim shared player. Retained for redirect
   * purposes.
   */
  PROTOTYPE_LEGACY_ROUTE_2: route(
    's',
    param('shareID'),
    'prototype',
    'a',
    param('artboardUUID')
  ),
  /**
   * Prototype player view using the web renderer.
   */
  PROTOTYPE_PLAYER: route(
    's',
    param('shareID'),
    'prototype',
    /**
     * Identifies the Prototype the user is viewing, i.e. the start point
     * Artboard's UUID.
     */
    param('prototypeArtboardUUID'),
    /**
     * Identifies the current Artboard the user is viewing in the flow.
     */
    'a',
    param('currentArtboardUUID')
  ).withQueryParams(
    'fullscreen',
    'hotspots',
    'comments',
    'annotation',
    'resizeMode'
  ),

  /* Legacy BabyCloud Keys */
  MY_DOCUMENTS: route('documents', 'my-documents'),
  SHARED_WITH_ME: route('documents', 'shared-with-me'),
  ALL_DOCUMENTS: route('documents', 'all-documents'),
  LIBRARIES: route('documents', 'libraries'),
  TRASH: route('documents', 'trash'),
  PERSONAL_DOCUMENTS: route('documents'),
  PERSONAL_PROJECT: route('documents', 'p', param('projectId')),
  DOCUMENTS: route('documents', param('filter')),

  SHARE_PROTOTYPES: route('s', param('shareID'), 'prototypes'),

  // Design system manager
  CWV_SYMBOLS: route('s', param('shareID'), 'symbols'),
  CWV_TEXT_STYLES: route('s', param('shareID'), 'text-styles'),
  CWV_LAYER_STYLES: route('s', param('shareID'), 'layer-styles'),
  CWV_COLOR_VARIABLES: route('s', param('shareID'), 'color-variables'),
  DESIGN_SYSTEM: route(
    'workspace',
    param('workspaceId'),
    'design-system',
    param('designSystemId')
  ),
  DESIGN_SYSTEM_PAGES: route(
    'workspace',
    param('workspaceId'),
    'design-system',
    param('designSystemId'),
    'page',
    param('pageId')
  ),
}

export const versionedRoutes = {
  SHARE_VIEW: {
    LATEST: routes.SHARE_VIEW,
    VERSION: route('s', param('shareID'), 'v', param('versionShortId')),
  },
  /** View all Artboards of a single page within a document */
  SHARE_PAGE_VIEW: {
    LATEST: routes.SHARE_PAGE_VIEW,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'p',
      param('pageUUID')
    ),
  },
  SHARE_PAGE_CANVAS_VIEW: {
    LATEST: routes.SHARE_PAGE_CANVAS_VIEW,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'p',
      param('pageUUID'),
      'canvas'
    ).withQueryParams('posX', 'posY', 'zoom', 'annotation'),
  },
  ARTBOARD_DETAIL: {
    LATEST: routes.ARTBOARD_DETAIL,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'a',
      param('permanentArtboardShortId')
    ),
  },
  ARTBOARD_DETAIL_UUID: {
    LATEST: routes.ARTBOARD_DETAIL_UUID,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'a',
      'uuid',
      param('artboardUUID')
    ),
  },
  FRAME: {
    LATEST: routes.FRAME,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'f',
      param('frameUUID')
    ),
  },
  PROTOTYPE_LEGACY_ROUTE_1: {
    LATEST: routes.PROTOTYPE_LEGACY_ROUTE_1,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'a',
      param('permanentArtboardShortId'),
      'play'
    ),
  },
  PROTOTYPE_LEGACY_ROUTE_2: {
    LATEST: routes.PROTOTYPE_LEGACY_ROUTE_2,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'prototype',
      'a',
      param('artboardUUID')
    ),
  },
  PROTOTYPE_PLAYER: {
    LATEST: routes.PROTOTYPE_PLAYER,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'prototype',
      param('prototypeArtboardUUID'),
      'a',
      param('currentArtboardUUID')
    ),
  },
  SHARE_PROTOTYPES: {
    LATEST: routes.SHARE_PROTOTYPES,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'prototypes'
    ),
  },
  CWV_SYMBOLS: {
    LATEST: routes.CWV_SYMBOLS,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'symbols'
    ),
  },
  CWV_TEXT_STYLES: {
    LATEST: routes.CWV_TEXT_STYLES,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'text-styles'
    ),
  },
  CWV_LAYER_STYLES: {
    LATEST: routes.CWV_LAYER_STYLES,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'layer-styles'
    ),
  },
  CWV_COLOR_VARIABLES: {
    LATEST: routes.CWV_COLOR_VARIABLES,
    VERSION: route(
      's',
      param('shareID'),
      'v',
      param('versionShortId'),
      'color-variables'
    ),
  },
}
export type VersionedRoutes = typeof versionedRoutes
export type VersionedRouteKeys = keyof VersionedRoutes
export const versionedRouteKeys = Object.keys(
  versionedRoutes
) as VersionedRouteKeys[]

export type RouteKeys = keyof typeof routes

type RouteCreateFn<TKey extends RouteKeys> = (typeof routes)[TKey]['create']

export type RouteParam<TKey extends RouteKeys> = Parameters<
  RouteCreateFn<TKey>
>[0]

export type RouteParams<TKey extends RouteKeys> = {
  [key in keyof RouteParam<TKey>]: string
}

/**
 * @example
 * // usage example if only a single route is used for a single component:
 * const Component: FC<RouteProps<'FRAME'>>
 *
 * @example
 * // Usage example if multiple routes uses the same component:
 * type ArtboardRouteProps = RouteComponentProps<
 *     Partial<RouteParams<'FRAME'>> & RouteParams<'ARTBOARD_DETAILS'>
 *   >
 * const Component: FC<ArtboardRouteProps>
 *
 */
export type RouteProps<TKey extends RouteKeys> = RouteComponentProps<
  RouteParams<TKey>
>

export type QueryParams<TKey extends RouteKeys> = NonNullable<
  RouteParam<TKey>['query']
>

/** Versioned routes */
type VersionedRouteCreateFn<TKey extends VersionedRouteKeys> =
  (typeof versionedRoutes)[TKey]['VERSION']['create']

export type VersionedRouteParam<TKey extends VersionedRouteKeys> = Parameters<
  VersionedRouteCreateFn<TKey>
>[0]
export type VersionedRouteParams<TKey extends VersionedRouteKeys> = {
  [key in keyof VersionedRouteParam<TKey>]: string
}
export type VersionedRouteProps<TKey extends VersionedRouteKeys> =
  RouteComponentProps<VersionedRouteParams<TKey>>

/**
 * @deprecated use versionedRoutes constant instead
 */
export const createGetVersionedRoute =
  (routes: VersionedRoutes) =>
  (key: keyof VersionedRoutes, latest = true) => {
    const latestOrVersionKey = latest ? 'LATEST' : 'VERSION'

    if (routes[key] && routes[key][latestOrVersionKey]) {
      return routes[key][latestOrVersionKey]
    }

    // This part actually never happens in the code,
    // and shouldn't be allowed in the first place
    // the only case where this is triggered is within unit tests
    // anyway, leaving it as it is for backwards compatibility
    if (routes[key]) {
      return routes[key] as any as Route<any>
    }

    throw new Error(`No route found for key: ${key}`)
  }

/**
 * @deprecated use versionedRoutes constant instead
 */
export const getVersionedRoute = createGetVersionedRoute(versionedRoutes)

export const getAllTemplatesForVersionedRoute = (key: VersionedRouteKeys) => {
  return [getVersionedRoute(key, true), getVersionedRoute(key, false)].map(
    route => route.template()
  )
}

export const getAllVersionedRouteTemplates = () =>
  getKeys(versionedRoutes).map(getAllTemplatesForVersionedRoute).flat()

/**
 * getRouteTemplates
 *
 * Return a list with all routes templates in string format.
 * e.g.:
 *
 * ```
 * const templates = getRouteTemplates({ SHARE_VIEW: route('s', param('shareID')) })
 * console.log(templates) // ['s/:shareID']
 * ```
 *
 */
export const getRouteTemplates = (
  appRoutes: typeof routes = routes
): string[] => Object.values(appRoutes).map(value => value.template())

export const getRouteTemplate = (key: RouteKeys): string =>
  routes[key].template()
